var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"max-width":"700"}},[_c('v-slide-group',{attrs:{"multiple":_vm.multiple,"show-arrows":""},on:{"change":function($event){_vm.$emit(
      'onSelectedCompanies',
      _vm.multiple
        ? _vm.getCompanies(_vm.selectedCompanies)
        : _vm.getCompany(_vm.selectedCompanies)
    )}},model:{value:(_vm.selectedCompanies),callback:function ($$v) {_vm.selectedCompanies=$$v},expression:"selectedCompanies"}},_vm._l((_vm.companies),function(company){return _c('v-slide-item',{key:company._id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var active = ref.active;
    var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.disabled,"input-value":active,"active-class":"purple white--text","depressed":"","rounded":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(company.alias)+" ")])]}}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }